
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/


/*
* BASE DEPENDENCIES
*/
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&display=swap');
@font-face {
	font-family: "Bely Display W00 Regular";
	src: url("/v-202410170829/fonts/local/Bely Display W00 Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

/*
* SHARED
*/
@import "shared/functions.scss";
@import "shared/mixins.scss";
@import "shared/vars";
@import "shared/animations";
@import "shared/typography";
@import "shared/helpers";
@import "shared/overrides";

/*
* COMMON BLOCKS
*/

@import "common/skeleton";
@import "common/grid";
@import "common/templates";
@import "common/layout";
@import "common/header";
@import "common/footer";
@import "common/article";
//@import "common/masonry";
@import "common/list";

/*
* DESIGN COMPONENTS
*/

@import "elements/button";
@import "elements/input";
//@import "elements/input-date";
//@import "elements/input-file";
//@import "elements/input-range";
//@import "elements/input-time";
//@import "elements/select";
@import "elements/textarea";
//@import "elements/blockquote";

@import "blocks/hamburgers/hamburgers";
@import "blocks/popup";
@import "blocks/breadcrumb-nav";
//@import "blocks/checkbox";
//@import "blocks/switch";
//@import "blocks/radio";
@import "blocks/form";
@import "blocks/contact-form";
@import "blocks/contact";
@import "blocks/search";
@import "blocks/gte";
@import "blocks/main-nav";
//@import "blocks/tags";
//@import "blocks/figure";
@import "blocks/nav";
