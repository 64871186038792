.breadcrumbs {
	text-align: right;
	color: $color-dark;
	padding-top: 0;
	padding-bottom: 1.5em;
	@include min(600px){
		padding-top: 1.5em;
	}
	.centered.-padded{
		padding-left: 2%;
		padding-right: 2%;
	}
}

.breadcrumb-nav {
	margin-top: 0;
	margin-bottom: 0;

	&__item {
		display: inline;

		&:not(.-active)::after {
			color: #9a9a9a;
			content: " \>";
			padding: 0 0.5rem;
		}
	}

	&__link {
		font-weight: 500;
		text-decoration: underline;
		display: inline-block;
	}

	@include link(".breadcrumb-nav__link") {
		color: $color-brand;
		text-decoration: underline;
	}

	@include link(".breadcrumb-nav__link.-active") {
		color: $color-brand;
		pointer-events: none;
		font-weight: 700;
		text-decoration: none;
	}

	@include link-over(".breadcrumb-nav__link") {
		text-decoration: none;
	}
}
