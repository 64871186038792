.page-footer,
.homepage-footer {
	text-align: center;
	background-color: $color-brand;
	color: $color-light;
	padding: 4.5em 0 3em;

	@include link {
		color: $color-light;
	}
}
