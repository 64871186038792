.list {
	list-style-type: none;
	padding: 0;
	margin: 0 0 1rem;

	.list {
		padding: 0 0 0 2em;
	}

	&__item {
		margin: 0 0 .7em;
	}

	&__description {
		margin-top: .25em;
	}
	.news &,
	.board &{
		@include grid(500px 2 3%, 800px 4 3%);
		margin: 0;
	}
	.news &{
		margin-bottom: 2rem;
	}
}

.org-contacts {
	&__contact {
		span:not(:first-of-type) {
			&::before {
				background-color: $color-secondary;
			}
		}
	}
}
