@charset "UTF-8";
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/
/*
* BASE DEPENDENCIES
*/
@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: "Bely Display W00 Regular";
  src: url("/v-202410170829/fonts/local/Bely Display W00 Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/*
* SHARED
*/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/*==============================
	DEFAULTS
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	TABS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*==============================
	CALENDAR -PAGE
==============================*/
@keyframes fade-into-foreground {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes fade-into-background {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.75);
    opacity: 0;
  }
}

@keyframes pop-into-foreground {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pop-into-foreground-with-bounce {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  72% {
    transform: scale(1.07);
    opacity: .72;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
  }
}

body {
  font-family: "Figtree", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Bely Display W00 Regular", sans-serif;
  color: #1e305e;
}

h1, h2, h3, h4 {
  font-weight: 700;
  line-height: 1.15;
  margin: 0 0 .3em 0;
  /* * + & {
		margin-top: 2em;
	} */
}

h5, h6 {
  font-size: 600;
  margin: 0 0 .5em 0;
}

* + h5, * + h6 {
  margin-top: 1.65em;
}

h2,
h3.-size-h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.875rem;
}

h4 {
  font-size: 1.75rem;
}

h5 {
  font-size: 1.625rem;
}

h6 {
  font-size: 1.5rem;
}

a,
a:link {
  color: #1e305e;
}

a:visited {
  color: #2a4485;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

._fsr {
  position: absolute !important;
  left: -9999rem !important;
  top: auto;
  outline: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

._nls {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

/*
* COMMON BLOCKS
*/
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  font-family: "Figtree", sans-serif;
}

.centered {
  max-width: 1510px;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
  margin: 0 -2.25vw 3em;
}

.grid__item {
  flex-grow: 0;
  padding: 2.25vw;
}

.grid__item.-fl {
  display: flex;
  flex-direction: column;
}

.grid__item.-fl > :only-child {
  flex-grow: 1;
}

.grid.-no-spacing {
  margin-left: 0;
  margin-right: 0;
}

.grid.-no-spacing .grid__item {
  padding: 0;
}

.grid.-halves .grid__item {
  display: grid;
}

@media screen and (min-width: 649px) {
  .grid.-halves .grid__item {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

.grid.-thirds .grid__item {
  display: grid;
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .grid.-thirds .grid__item {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

@media screen and (min-width: 799px) {
  .grid.-thirds .grid__item {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

@media screen and (min-width: 800px) {
  .grid.-thirds:not(.-no-spacing) {
    margin-left: -1.6875vw;
    margin-right: -1.6875vw;
  }
  .grid.-thirds:not(.-no-spacing) .grid__item {
    padding: 1.6875vw;
  }
}

.grid.-quarters .grid__item {
  display: grid;
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .grid.-quarters .grid__item {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

@media screen and (min-width: 800px) and (max-width: 1024px) {
  .grid.-quarters .grid__item {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

@media screen and (min-width: 1023px) {
  .grid.-quarters .grid__item {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

@media screen and (min-width: 800px) {
  .grid.-quarters:not(.-no-spacing) {
    margin-left: -1.6875vw;
    margin-right: -1.6875vw;
  }
  .grid.-quarters:not(.-no-spacing) .grid__item {
    padding: 1.6875vw;
  }
}

@media screen and (min-width: 1024px) {
  .grid.-quarters:not(.-no-spacing) {
    margin-left: -1.125vw;
    margin-right: -1.125vw;
  }
  .grid.-quarters:not(.-no-spacing) .grid__item {
    padding: 1.125vw;
  }
}

.agnostic-grid {
  margin: 0 0 3em;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
}

a.keyboard-focus:focus,
video.keyboard-focus:focus,
button.keyboard-focus:focus,
summary.keyboard-focus:focus {
  outline: 3px lightskyblue solid;
  outline-offset: 2px;
  -moz-outline-radius: 3px;
}

/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
.input, .textarea {
  transition: border-color .11s ease, box-shadow .21s ease;
  box-shadow: 0 0 0;
  width: 100%;
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: 0px;
  border-color: #cccccc;
  border-width: 1px;
  background-color: #ffffff;
  color: #2a2a2a;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input:focus, .textarea:focus {
  border-color: #6b9aeb;
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.12);
  outline: 0;
}

.input[disabled], .textarea[disabled] {
  border-color: #e3e3e3;
  background-color: #ebebeb;
  color: #444444;
  cursor: not-allowed;
}

/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
[class*="lazy-"] {
  transition: filter .5s ease-out;
  filter: blur(20px);
}

[class*="lazy-"]:not(.loaded) {
  display: block;
  background: #dedede;
  width: 100%;
  min-width: 240px;
  padding-bottom: 56.25%;
}

[class*="lazy-"].loaded {
  filter: blur(0);
}

body {
  color: #6d6d6d;
}

::selection {
  background-color: #1a86d0;
  color: #ffffff;
}

.main-wrapper {
  border-top: 2px solid #d2d2d2;
}

@media screen and (min-width: 800px) {
  .main-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }
}

.main {
  padding-top: 4.5em;
}

@media screen and (min-width: 800px) {
  .main {
    flex-grow: 1;
    padding-left: 4vw;
    padding-bottom: 4em;
  }
  .-nosidebar .main {
    padding-left: 0;
  }
}

.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: 2em;
}

.table-wrapper table {
  margin-bottom: 1em;
}

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  margin-bottom: 2.3rem;
}

.sidebar {
  position: relative;
  padding-top: 2em;
  padding-bottom: 2em;
  min-width: 340px;
}

.sidebar::after {
  position: absolute;
  content: "";
  left: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: #e2e2e2;
}

@media screen and (min-width: 800px) {
  .sidebar {
    width: 23.17881%;
    flex-shrink: 0;
  }
  .sidebar::after {
    content: normal;
  }
}

.content {
  margin-left: auto;
  margin-right: auto;
}

.centered {
  max-width: 1510px;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

.centered.-narrow {
  max-width: 920px;
}

.centered.-padded {
  width: 94%;
  padding-left: 4%;
  padding-right: 4%;
}

.centered.-padded-left {
  padding-left: 4%;
}

.centered.-padded-right {
  padding-right: 4%;
}

.more {
  text-align: center;
}

.more__button {
  background-color: #f03f23;
  padding: 1.7em 2em;
  font-weight: 500;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 800;
  letter-spacing: 1px;
  min-width: 235px;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.more__button,
.more__button:link,
.more__button:visited {
  color: #ffffff;
  text-decoration: none;
}

.more__button:hover,
.more__button:focus,
.more__button:active {
  background-color: #1e305e;
}

.board .list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
  margin: 0 0 3em;
  overflow: hidden;
}

.board .list__item {
  display: grid;
  flex-grow: 0;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 649px) {
  .board .list__item {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}

.board .list__item > :only-child {
  flex-grow: 1;
}

.board .list .board-card {
  flex-grow: 1;
}

.text-component {
  margin-bottom: 2em;
  overflow: hidden;
}

.text-component p:first-child {
  margin-top: 0;
}

.text-component p:last-child {
  margin-bottom: 0;
}

.text-component .figure.image {
  margin: 0;
}

.text-component .image-align-left,
.text-component .image-align-right {
  margin: 0;
}

@media screen and (min-width: 500px) {
  .text-component .image-align-left img,
  .text-component .image-align-right img {
    max-width: 42.5%;
  }
}

.text-component .image-align-left img {
  float: left;
  margin: 0 1em .75em 0;
}

.text-component .image-align-left > *:first-of-type img {
  clear: both;
}

.text-component .image-align-right img {
  float: right;
  margin: 0 0 .75em 1em;
}

.text-component .image-align-right > *:first-of-type img {
  clear: both;
}

.text-component .image-align-center {
  text-align: center;
}

.text-component .image-align-center img {
  margin: 0 1rem .75em;
  max-width: 100%;
}

.text-component [contenteditable="true"] .images-container:focus img, .text-component [contenteditable="true"] .images-container:hover img,
.text-component [contenteditable="true"] .image:focus img,
.text-component [contenteditable="true"] .image:hover img {
  box-shadow: 0 0 5px red;
}

.additional {
  overflow: hidden;
  color: #1e305e;
  padding: 0;
  font-weight: 500;
  border: 1px solid #838b95;
  border-width: 1px 0;
}

.additional a,
.additional a:link,
.additional a:visited {
  color: #ffffff;
  text-decoration: underline;
}

.additional a:hover,
.additional a:focus,
.additional a:active {
  text-decoration: none;
}

.additional__content {
  position: relative;
  max-width: 1700px;
}

@media screen and (min-width: 600px) {
  .additional__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 2rem;
  }
  .additional__content .additional__section {
    padding: 4rem 0 2rem;
  }
  .additional__content .additional__section:nth-child(even) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .additional__content .additional__section.-write-us::before, .additional__content .additional__section.-links::before {
    left: 100%;
  }
}

@media screen and (min-width: 1360px) {
  .additional__content {
    grid-template-columns: 0.8fr 1.4fr 1.1fr 1.1fr;
    gap: 4rem;
  }
  .additional__content .additional__section {
    padding: 6.2rem 0 2rem;
  }
  .additional__content .additional__section.-write-us::before {
    content: normal;
  }
  .additional__content .additional__section:nth-child(even) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.additional__section {
  padding: 2rem 0 2rem;
}

.additional__section a,
.additional__section a:link,
.additional__section a:visited {
  color: #1e305e;
}

.additional__section:nth-child(even) {
  background-color: #1e305e;
  color: #ffffff;
}

.additional__section:nth-child(even) .element-type-heading {
  color: #ffffff;
}

.additional__section:nth-child(even) a,
.additional__section:nth-child(even) a:link,
.additional__section:nth-child(even) a:visited {
  color: #ffffff;
}

.additional__section.-write-us {
  position: relative;
}

.additional__section.-write-us::before {
  content: '';
  position: absolute;
  width: 999em;
  left: 100%;
  top: 0;
  bottom: 0;
  background-color: #1e305e;
}

.additional__section.-write-us::before {
  z-index: -1;
  left: -100%;
}

.additional__section.-links {
  position: relative;
  min-height: 450px;
}

.additional__section.-links::before {
  content: '';
  position: absolute;
  width: 999em;
  left: 100%;
  top: 0;
  bottom: 0;
  background-color: #1e305e;
}

.additional__section.-links::before {
  z-index: -1;
  left: -100%;
}

.additional .element-type-heading {
  font-size: 2.25rem;
  color: #f03f23;
  font-weight: 400;
  margin-bottom: 1.25em;
}

@media screen and (min-width: 600px) {
  .additional .element-type-heading {
    font-size: 2.5625rem;
  }
}

.additional__link-to-header {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 102px;
  height: 102px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f03f23;
  transform: translateY(50%);
}

.-additional__link-to-header {
  position: relative;
  max-width: 1780px;
}

.all_rights__logo {
  display: block;
  margin-bottom: 0.5em;
}

.all_rights__subheading {
  margin-top: 0.5em;
  font-size: 1rem;
  display: block;
  font-weight: 300;
  font-family: "Figtree", sans-serif;
}

.attachments {
  margin: 0 0 3em;
  grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
  grid-gap: 3rem 1.5rem;
}

.section.-banners {
  padding-top: 4rem;
  padding-bottom: 0;
}

.section.-banners .centered {
  max-width: 1540px;
}

.banners__bottom-bg {
  display: block;
  margin: 0 auto;
}

.header {
  position: relative;
  background-color: #ffffff;
  color: #1e305e;
  padding: 0 0 1.25rem;
  z-index: 30;
}

.header a,
.header a:link,
.header a:visited {
  color: #1e305e;
}

@media screen and (min-width: 600px) {
  .header {
    padding: 0;
  }
}

@media screen and (min-width: 1024px) {
  .header {
    color: #292A2D;
  }
  .header a,
  .header a:link,
  .header a:visited {
    color: #292A2D;
  }
}

.header__title {
  position: relative;
  margin: 0 auto;
}

@media screen and (min-width: 500px) {
  .header__title {
    margin: 0;
  }
}

.header__home-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.header__content {
  position: relative;
  z-index: 2;
  max-width: 1764px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem 0;
}

@media screen and (min-width: 1024px) {
  .header__content {
    flex-wrap: nowrap;
  }
}

.header__home-link {
  color: inherit;
  text-decoration-color: none;
}

.header__logo {
  display: block;
  font-size: 1rem;
}

.header__heading {
  position: relative;
  text-align: center;
  padding: 0;
  color: #292A2D;
  line-height: 1;
  font-size: 2.125rem;
}

.header__subheading {
  display: block;
  margin-top: .5em;
  font-size: 0.9375rem;
}

.header__heading-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 3rem auto 0;
  width: 100%;
  padding: 1.25rem 4rem 0.75rem;
}

@media screen and (min-width: 800px) {
  .header__heading-container {
    margin: 0 auto 0;
  }
}

@media screen and (min-width: 1024px) {
  .header__heading-container {
    padding: 1rem 1.5rem 0.5rem 0;
    margin: 0;
    width: auto;
    flex-shrink: 0;
  }
}

.header__nav {
  display: flex;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .header__nav {
    width: auto;
    flex-grow: 1;
  }
}

.header__nav-button {
  position: absolute;
  right: 1rem;
  top: 5rem;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 600px) {
  .header__nav-button {
    display: none;
  }
}

.header__nav-button, .header__nav-button:link, .header__nav-button:visited {
  text-decoration: none;
}

.header__nav-button > .hamburger {
  padding: 8px 7px;
  border-radius: 5px;
}

.header__nav-button .nav-button__title {
  font-size: 0.9375rem;
  margin: 0;
}

.header__additional {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: flex-end;
  align-items: center;
  padding: 0;
  flex-direction: row-reverse;
  gap: 1rem;
}

@media screen and (min-width: 600px) {
  .header__additional {
    gap: 0;
  }
}

@media screen and (min-width: 1024px) {
  .header__additional {
    gap: 1.4rem;
    right: 0;
  }
}

@media screen and (min-width: 1360px) {
  .header__additional {
    flex-direction: row;
    position: static;
  }
}

@media screen and (min-width: 1500px) {
  .header__additional {
    border-left: 1px solid #8d8d8d;
    padding: 1.5rem 0 1.5rem 3rem;
  }
}

.header__social .list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0;
}

.header__social .list__item {
  display: inline-flex;
  margin-bottom: 0;
}

.header__social .list__link {
  display: inline-flex;
}

.header__social .list svg {
  fill: #292A2D;
}

.header__social object {
  pointer-events: none;
}

.header__social a,
.header__social a:link,
.header__social a:visited {
  text-decoration: none;
}

.header__social a svg,
.header__social a:link svg,
.header__social a:visited svg {
  fill: grey;
}

.header__social a:hover svg,
.header__social a:focus svg,
.header__social a:active svg {
  fill: #292A2D;
}

.virtual-anchor {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #ffffff;
  gap: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  width: 100%;
}

.virtual-anchor::after {
  display: inline-block;
  content: "";
  width: 35px;
  height: 26px;
  background: transparent url(/images/local/icons/v-202410170829/virtual-anchor-arrow.png) no-repeat center right;
}

.virtual-anchor, .virtual-anchor:link, .virtual-anchor:visited {
  color: #1e305e;
  text-decoration: none;
}

.virtual-anchor:hover, .virtual-anchor:focus, .virtual-anchor:active {
  text-decoration: underline;
}

@media screen and (min-width: 600px) {
  .virtual-anchor {
    position: absolute;
    bottom: 7.4rem;
    right: 3%;
    z-index: 20;
    width: auto;
  }
}

@media screen and (min-width: 1024px) {
  .virtual-anchor {
    padding: 1.4rem 4rem;
    font-size: 1.75rem;
  }
  .virtual-anchor::after {
    width: 85px;
    height: 26px;
  }
}

.page-footer,
.homepage-footer {
  text-align: center;
  background-color: #1e305e;
  color: #ffffff;
  padding: 4.5em 0 3em;
}

.page-footer a,
.page-footer a:link,
.page-footer a:visited,
.homepage-footer a,
.homepage-footer a:link,
.homepage-footer a:visited {
  color: #ffffff;
}

.article__heading {
  font-size: 2.75rem;
  line-height: 1.15;
  margin: 0 0 .75em;
  font-weight: 600;
}

.-nosidebar .article__heading {
  text-align: center;
}

.article__approved {
  padding: 1em 0 0;
}

.article__footer {
  margin: 1.5em 0 2em;
  padding: 0 0 2em;
}

.article__footer div {
  display: inline-block;
}

.article__footer div input[type="date"],
.article__footer div input[type="time"] {
  display: inline;
  border: 0;
  padding: 0 .5em;
  border-radius: 3px;
}

.article__footer .invalid ul {
  margin: 0;
  padding: 0 0 0 .5em;
}

.article__perex {
  font-size: 1.1em;
  margin-bottom: 1.5em;
}

.article__image {
  margin-left: auto;
  margin-right: auto;
}

.article__body {
  margin-bottom: 0em;
}

.article__author {
  text-align: left;
  clear: both;
  margin: 1.5em 0;
  display: inline;
  font-style: normal;
}

.article__datespan {
  margin-bottom: 3em;
}

.article__date {
  font-weight: bold;
}

.article-link__image {
  display: block;
  max-width: 240px;
  margin: 0 0 1rem;
  flex-shrink: 0;
}

.article__link-elements .article-link__image {
  max-width: 100px;
}

@media screen and (min-width: 500px) {
  .article-link__image {
    display: inline-block;
    float: left;
    margin: 0 1rem 0 0;
  }
}

.article-link__title {
  font-size: 1.125rem;
}

.article-link__description {
  font-size: 1rem;
  margin-top: 0;
}

.article-link,
.article-link:link {
  color: #1e305e;
  text-decoration: underline;
}

.article-link:visited {
  color: #121c37;
}

.article-link:hover,
.article-link:focus,
.article-link:active {
  text-decoration: none;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1rem;
}

.list .list {
  padding: 0 0 0 2em;
}

.list__item {
  margin: 0 0 .7em;
}

.list__description {
  margin-top: .25em;
}

.news .list,
.board .list {
  display: grid;
  margin: 0;
}

@media screen and (min-width: 500px) and (max-width: 800px) {
  .news .list,
  .board .list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3%;
    gap: 3%;
  }
}

@media screen and (min-width: 799px) {
  .news .list,
  .board .list {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3%;
    gap: 3%;
  }
}

.news .list {
  margin-bottom: 2rem;
}

.org-contacts__contact span:not(:first-of-type)::before {
  background-color: #f03f23;
}

/*
* DESIGN COMPONENTS
*/
.button {
  border-radius: 0;
  padding: 5px 2em;
  margin: 0;
  min-height: 54px;
  background-color: #1e305e;
  color: #ffffff;
  text-align: center;
  transition: background-color .15s ease;
  position: relative;
  font-size: 0.9375rem;
  font-weight: 800;
  min-width: 180px;
  cursor: pointer;
}

.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active {
  z-index: 3;
  outline: 0;
}

.button[disabled] {
  color: #999999;
  background-color: #ebebeb;
  cursor: not-allowed;
}

.button.-icon::before {
  vertical-align: middle;
  margin-right: .75em;
  display: inline-block;
}

.button.-block {
  display: block;
  width: 100%;
}

.button.-large {
  font-size: 1.15rem;
  padding: 1em 3em;
}

.button.-inverted {
  background-color: #1e305e;
  color: #ffffff;
}

.button.-primary {
  background-color: #f03f23;
  color: #ffffff;
}

.button.-primary.-inverted {
  background-color: #ffffff;
  color: #f03f23;
}

.button.-primary:not([disabled]):hover, .button.-primary:not([disabled]):focus, .button.-primary:not([disabled]):active {
  background-color: #1e305e;
  color: #ffffff;
}

.input {
  min-height: 54px;
  padding: .3em 1em;
}

.input.-inline {
  display: inline-block;
  width: auto;
  margin-right: .25em;
}

.input.-main {
  min-height: 54px;
  font-size: 1.15em;
}

.textarea {
  padding: .5em 1em;
  resize: vertical;
  height: auto;
  min-height: 10em;
}

@use "sass:math";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
/* MARKUP */
/*
<button class="hamburger" type="button">
  <span class="hamburger-box">
    <span class="hamburger-inner"></span>
  </span>
</button>
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #1e305e;
}

.hamburger-box {
  width: 22px;
  height: 19px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 22px;
  height: 3px;
  background-color: #1e305e;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -8px;
}

.hamburger-inner::after {
  bottom: -8px;
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.universal-popup-component {
  position: fixed;
  overflow: hidden;
  z-index: 90;
  left: -9999rem;
  width: 0;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  color: #323232;
}

.universal-popup-component::before {
  position: fixed;
  z-index: 1;
  opacity: 0;
  transition: opacity .15s ease-out;
}

.universal-popup-component__form {
  position: relative;
  z-index: 2;
  transform: scale(0);
  background-color: #ffffff;
  border-radius: 15px;
  transition: transform .15s ease-out, box-shadow .2s ease-out;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  max-width: 80vw;
  max-height: 70vh;
  min-height: 10em;
  width: 36em;
  padding: 3vmax;
}

.universal-popup-component__heading {
  font-family: inherit;
  color: inherit;
  margin: 0 0 .66em;
  padding: 0;
  text-transform: none;
  font-size: 2.1875rem;
}

.universal-popup-component__row {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.universal-popup-component__row:empty {
  display: none;
}

.universal-popup-component__row:not(:last-child) {
  margin-bottom: 20px;
}

.universal-popup-component__row.-end {
  align-items: flex-end;
}

.universal-popup-component__row.-center {
  align-items: center;
}

.universal-popup-component__label {
  font-family: inherit;
  font-size: inherit;
  margin: 0 0 6px;
}

.universal-popup-component__input {
  font-family: inherit;
  font-size: inherit;
  height: 54px;
  border-radius: 6px;
  border: 1px #cccccc solid;
  outline: 0;
}

.universal-popup-component__button {
  font-family: inherit;
  font-size: inherit;
  border-radius: 5px;
  background-color: #888888;
  color: #ffffff;
}

.universal-popup-component__close {
  font-size: 1.25rem;
  font-weight: 600;
  position: absolute;
  right: 1em;
  top: 1em;
  line-height: 0;
  width: 20px;
  height: 20px;
  text-decoration: none;
}

.universal-popup-component.-visible, .universal-popup-component:target {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.universal-popup-component.-visible::before, .universal-popup-component:target::before {
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background: rgba(0, 0, 0, 0.7);
}

@keyframes popup-bounce {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.universal-popup-component.-visible .universal-popup-component__form, .universal-popup-component:target .universal-popup-component__form {
  z-index: 2;
  animation: popup-bounce .3s ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: 50% 100%;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}

.breadcrumbs {
  text-align: right;
  color: #292A2D;
  padding-top: 0;
  padding-bottom: 1.5em;
}

@media screen and (min-width: 600px) {
  .breadcrumbs {
    padding-top: 1.5em;
  }
}

.breadcrumbs .centered.-padded {
  padding-left: 2%;
  padding-right: 2%;
}

.breadcrumb-nav {
  margin-top: 0;
  margin-bottom: 0;
}

.breadcrumb-nav__item {
  display: inline;
}

.breadcrumb-nav__item:not(.-active)::after {
  color: #9a9a9a;
  content: " \>";
  padding: 0 0.5rem;
}

.breadcrumb-nav__link {
  font-weight: 500;
  text-decoration: underline;
  display: inline-block;
}

.breadcrumb-nav .breadcrumb-nav__link,
.breadcrumb-nav .breadcrumb-nav__link:link,
.breadcrumb-nav .breadcrumb-nav__link:visited {
  color: #1e305e;
  text-decoration: underline;
}

.breadcrumb-nav .breadcrumb-nav__link.-active,
.breadcrumb-nav .breadcrumb-nav__link.-active:link,
.breadcrumb-nav .breadcrumb-nav__link.-active:visited {
  color: #1e305e;
  pointer-events: none;
  font-weight: 700;
  text-decoration: none;
}

.breadcrumb-nav .breadcrumb-nav__link:hover,
.breadcrumb-nav .breadcrumb-nav__link:focus,
.breadcrumb-nav .breadcrumb-nav__link:active {
  text-decoration: none;
}

.form {
  padding: 2em 0;
  margin: 0 0 2em 0;
}

.form__fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.form__fields > :not(.form__section) {
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.form__section {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 50%;
  padding: 0 1.25rem;
  min-width: 18em;
  margin-bottom: 1.1em;
}

.form__section.-inline {
  display: inline-block;
  padding: 0;
}

.form__section.-full {
  width: 100%;
  flex-basis: 100%;
}

.form__section.-to-end {
  justify-content: flex-end;
}

.form__section.-to-center, .form__section.-to-right {
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 1;
  width: 100%;
  margin-top: 1em;
}

.form__section.-to-center {
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}

.form__section.-to-right {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
}

.form__section.-to-left {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.form__section .label {
  margin-bottom: .6em;
}

.form__section .label.-inline {
  display: inline-block;
  margin-right: .25em;
}

.form__section .button:nth-last-child(n+2), .form__section .button:nth-last-child(n+2) ~ .button {
  margin-bottom: .5em;
}

.form__section .button:not(:first-child):not(.-block) {
  margin-left: .5em;
}

.form__label {
  display: block;
  margin-bottom: .4em;
  cursor: default;
}

.form__file-value {
  color: inherit;
  opacity: .8;
}

.form__fieldset {
  padding: .85em 1.5em .625em;
  border: 1px #cccccc solid;
  border-radius: 0px;
}

.form__legend {
  padding: 0 .5em;
  font-size: 1.35rem;
  font-weight: bold;
}

.inline-form.form {
  padding-bottom: 0;
}

.inline-form__fields.-bordered-top {
  border-top: 2px solid #c3c3c3;
  padding-top: 1.5em;
}

.inline-form__fields.-bordered-bottom {
  border-bottom: 2px solid #c3c3c3;
  padding-bottom: .5em;
}

.contact-form {
  padding-top: 0;
}

.contact-form .form__section {
  margin-bottom: .5em;
}

.contact-form__input, .contact-form__textarea {
  background-color: #ffffff;
  border-color: #292A2D;
  color: #292A2D;
  border-radius: 1px;
  font-style: italic;
}

.contact-form__input {
  min-height: 55px;
}

.contact-form__info:first-child {
  margin: .55em 0 .9em;
}

.contact-form__button.-primary {
  padding: 5px 2em;
  font-size: 1rem;
}

.contact-form__button.-primary:not([disabled]):hover, .contact-form__button.-primary:not([disabled]):focus, .contact-form__button.-primary:not([disabled]):active {
  background-color: #ffffff;
  color: #1e305e;
}

.contact__line {
  margin: 0 0 1em;
}

.contact__line.-phone, .contact__line.-email {
  padding-left: 30px;
  padding-top: .15em;
  padding-bottom: .15em;
  background-repeat: no-repeat;
  background-position: 0 50%;
}

.contact__line.-location {
  margin-bottom: 3em;
}

.contact__line.-phone {
  background-image: url(/images/local/icons/v-202410170829/phone.png);
}

.contact__line.-email {
  background-image: url(/images/local/icons/v-202410170829/email.png);
}

.contact__more {
  margin-top: 3.6rem;
  text-align: left;
}

.contact__more-button {
  padding: 5px 2em;
  min-height: 54px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 180px;
}

.contact .contact__more-button,
.contact .contact__more-button:link,
.contact .contact__more-button:visited {
  color: #ffffff;
  text-decoration: none;
}

.search {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  flex-grow: 0;
  padding: 1em 0 1em;
  margin-left: auto;
}

@media screen and (min-width: 600px) {
  .search {
    padding: 1rem 0 1rem 1rem;
  }
  .search__form {
    flex-grow: 0;
  }
  .search__input {
    width: 13em;
  }
}

/*******************************
* FULLSCREEN-SEARCH
*******************************/
.fullscreen-search-anchor {
  display: flex;
  z-index: 5;
  white-space: nowrap;
  font-family: "Bely Display W00 Regular", sans-serif;
}

.fullscreen-search-anchor__container {
  flex-shrink: 0;
}

.fullscreen-search-anchor__text {
  padding-right: .5em;
}

.fullscreen-search-anchor svg {
  display: inline-block;
  width: 23px;
  height: 24px;
}

.fullscreen-search-anchor, .fullscreen-search-anchor:link, .fullscreen-search-anchor:visited {
  text-decoration: underline;
}

.fullscreen-search-anchor:hover, .fullscreen-search-anchor:focus, .fullscreen-search-anchor:active {
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  .fullscreen-search-anchor {
    position: static;
  }
}

.fullscreen-search {
  transition: background-color .1s ease-out;
  position: fixed;
  top: 0;
  left: -999em;
  z-index: 19000;
  width: .1px;
  height: .1px;
  overflow: hidden;
  text-align: center;
  font-family: "Bely Display W00 Regular", sans-serif;
  background: rgba(240, 63, 35, 0.9);
}

.fullscreen-search:target, .fullscreen-search[data-is-shown="true"] {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: auto;
  height: auto;
  backdrop-filter: blur(8px) grayscale(100%);
}

.fullscreen-search form {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 10% 0 0 0;
  text-align: left;
  color: #ffffff;
  line-height: 1.4;
}

@media screen and (min-height: 35em) {
  .fullscreen-search form {
    padding-top: 14%;
  }
}

@media screen and (orientation: portrait) {
  .fullscreen-search form {
    padding-top: 33%;
  }
}

.fullscreen-search__close {
  transition: background-color .15s ease-out;
  position: absolute;
  top: 0;
  right: 0;
  width: 72px;
  height: 72px;
  cursor: pointer;
  background: transparent url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23ffffff' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E") no-repeat 50% 50%;
}

.fullscreen-search .fullscreen-search__close:hover,
.fullscreen-search .fullscreen-search__close:focus,
.fullscreen-search .fullscreen-search__close:active {
  background-color: #292A2D;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23ffffff' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E");
}

.fullscreen-search__label {
  font-size: 1.125rem;
  display: block;
  margin: 0 0 .9em 0;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
}

.fullscreen-search__container {
  position: relative;
  width: 100%;
}

.fullscreen-search__button {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
  appearance: none;
  border: 0;
  background: transparent;
  color: #ffffff;
  width: 2em;
  height: 2em;
  position: absolute;
  right: 0;
  top: 50%;
  margin: -1.2em 0 0 0;
  padding: 0;
}

.fullscreen-search__button:focus {
  box-shadow: none;
}

@media screen and (min-width: 1100px) {
  .fullscreen-search__button {
    width: 3.8em;
    height: 3.8em;
    margin: -2.2em 0 0 0;
  }
}

@media screen and (min-width: 1510px) {
  .fullscreen-search__button {
    width: 5em;
    height: 5em;
    margin: -2.5em 0 0 0;
  }
}

.fullscreen-search__input {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
  box-sizing: border-box;
  font-size: 1.5rem;
  width: 100% !important;
  height: auto !important;
  font-family: "Bely Display W00 Regular", sans-serif;
  background: transparent !important;
  color: #ffffff;
  outline: 0;
  font-weight: normal;
  padding: 0 1em 0 0 !important;
  border: dotted rgba(255, 255, 255, 0.8) !important;
  border-width: 0 0 2px !important;
  text-indent: 0 !important;
}

.fullscreen-search__input:focus {
  box-shadow: none !important;
}

.fullscreen-search__input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

@media screen and (min-width: 600px) {
  .fullscreen-search__input {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1510px) {
  .fullscreen-search__input {
    font-size: 5rem;
  }
}

.mutaceGte {
  display: flex;
  align-items: center;
}

#google_translate_element {
  display: inline-block;
  position: relative;
  display: none;
  overflow: hidden;
  flex-shrink: 0;
}

#google_translate_element.ready {
  display: inline-block;
}

@media screen and (min-width: 600px) {
  #google_translate_element.ready {
    margin-right: 14px;
  }
}

#google_translate_element .goog-te-select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  opacity: 0 !important;
}

#google_translate_element .goog-te-gadget {
  border: 0;
  width: 100%;
  padding-bottom: 1px;
  background-color: transparent;
  font-size: 0 !important;
  position: relative;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  display: none;
}

#google_translate_element .goog-te-gadget .goog-te-gadget-simple {
  display: none !important;
}

#google_translate_element .goog-te-gadget > * {
  font-size: 1rem !important;
}

#google_translate_element .goog-te-gadget::before {
  content: '';
  width: 28px;
  height: 28px;
  background-image: url(/images/global/v-202410170829/gte_logo.svg) !important;
  background-position: 0 0 !important;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

#google_translate_element .goog-te-gadget .goog-te-gadget-icon + span {
  display: none;
}

#jazyky {
  display: block;
}

#jazyky:empty {
  display: none;
}

#jazyky ul {
  list-style-type: none;
  margin: 0.5rem 0 0;
  padding: 0;
  display: inline-flex;
  align-items: baseline;
  gap: 0.5rem;
}

#jazyky ul li {
  margin: 0;
  font-size: 0;
}

#jazyky ul li a {
  color: #c3c3c3;
  display: inline-flex;
}

#jazyky ul li a::before {
  content: "";
  width: 20px;
  height: 20px;
  margin-right: .5rem;
  display: block;
  background-repeat: no-repeat;
  background-position: 50%;
}

#jazyky ul li a::after {
  font-size: 1rem;
}

#jazyky ul li .googleTranslateActiveLanguage {
  font-weight: bold;
  color: #292A2D;
}

#jazyky ul li .language-cs::before {
  background-image: url(/images/local/icons/gte/v-202410170829/cs.png);
}

#jazyky ul li .language-cs::after {
  content: 'CS';
}

#jazyky ul li .language-en::before {
  background-image: url(/images/local/icons/gte/v-202410170829/en.png);
}

#jazyky ul li .language-en::after {
  content: 'EN';
}

#jazyky ul li .language-de::before {
  background-image: url(/images/local/icons/gte/v-202410170829/de.png);
}

#jazyky ul li .language-de::after {
  content: 'DE';
}

#jazyky ul li .language-fr::after {
  content: 'FR';
}

#jazyky ul li .language-pl::after {
  content: 'PL';
}

#jazyky ul li .language-ru::after {
  content: 'RU';
}

#jazyky ul li .language-hu::after {
  content: 'HU';
}

#jazyky ul li a,
#jazyky ul li a:link,
#jazyky ul li a:visited {
  text-decoration: none;
}

#jazyky ul li a:hover,
#jazyky ul li a:focus,
#jazyky ul li a:active {
  text-decoration: underline;
}

body[style] {
  top: 0 !important;
}

@keyframes menuFadeIn {
  from {
    opacity: 0;
    transform: translateY(200%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes menuFadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(200%);
  }
}

.main-nav {
  position: relative;
  color: #1e305e;
  padding: 0 0 2rem;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .main-nav {
    margin-left: auto;
    width: auto;
    padding: 1rem 0 0;
  }
}

@media screen and (min-width: 1360px) {
  .main-nav {
    padding: 0 3rem 0 0;
  }
}

.main-nav .nav.-root-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  gap: 0 1rem;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .main-nav .nav.-root-nav {
    flex-direction: row;
  }
}

@media screen and (min-width: 1360px) {
  .main-nav .nav.-root-nav {
    gap: 0 3.25rem;
  }
}

.main-nav .nav__item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 0 0;
  text-align: center;
  font-size: 1.6875rem;
  font-family: "Bely Display W00 Regular", sans-serif;
}

.main-nav .nav__link {
  position: relative;
  display: block;
  padding: 0.35em 0;
  justify-content: center;
  line-height: 1;
}

.main-nav .nav__link::after {
  content: "";
  position: absolute;
  left: -0.25rem;
  right: -0.25rem;
  bottom: 100%;
  height: 2px;
  background-color: transparent;
  transition: bottom .3s ease-in-out, background-color .6s ease-in-out;
}

.main-nav .nav__link, .main-nav .nav__link:link, .main-nav .nav__link:visited {
  color: #1e305e;
  text-decoration: none;
}

.main-nav .nav__link:hover, .main-nav .nav__link:focus, .main-nav .nav__link:active {
  color: #f03f23;
}

.main-nav .nav__link:hover::after, .main-nav .nav__link:focus::after, .main-nav .nav__link:active::after {
  position: absolute;
  bottom: 0;
  background-color: #f03f23;
}

.main-nav .nav__link.-active, .main-nav .nav__link.-active-parent {
  text-decoration: none;
}

.main-nav .nav__link.-active, .main-nav .nav__link.-active:link, .main-nav .nav__link.-active:visited, .main-nav .nav__link.-active-parent, .main-nav .nav__link.-active-parent:link, .main-nav .nav__link.-active-parent:visited {
  color: #f03f23;
}

.main-nav .nav__description {
  display: none;
}

@media screen and (max-width: 599px) {
  .main-nav {
    transition: max-height 0.3s ease-out;
    max-height: 0;
    overflow: hidden;
    padding: 0;
  }
  .main-nav__item {
    animation: menuFadeOut 0.1s ease-out 0s forwards;
    opacity: 0;
  }
  .main-nav__item:nth-of-type(2) {
    animation-delay: 50ms !important;
  }
  .main-nav__item:nth-of-type(3) {
    animation-delay: 100ms !important;
  }
  .main-nav__item:nth-of-type(4) {
    animation-delay: 150ms !important;
  }
  .main-nav__item:nth-of-type(5) {
    animation-delay: 200ms !important;
  }
  .main-nav__item:nth-of-type(6) {
    animation-delay: 250ms !important;
  }
  .main-nav__item:nth-of-type(7) {
    animation-delay: 300ms !important;
  }
  .main-nav__item:nth-of-type(8) {
    animation-delay: 350ms !important;
  }
  .main-nav__item:nth-of-type(9) {
    animation-delay: 400ms !important;
  }
  .main-nav__item:nth-of-type(10) {
    animation-delay: 450ms !important;
  }
  .header__nav--toggled .main-nav {
    max-height: 20rem;
  }
  .header__nav--toggled .main-nav__item {
    animation: menuFadeIn 0.3s ease-out 0s forwards;
  }
}

.sidebar .nav {
  height: 100%;
  padding: 0 0 0 0;
  margin-top: 0;
  list-style-type: none;
}

.sidebar .nav__upper_node_link, .sidebar .nav__upper_node_link:link, .sidebar .nav__upper_node_link:visited {
  display: flex;
  align-items: center;
  color: #d21116;
  font-size: 1.25rem;
  font-weight: 700;
  gap: 1rem;
  align-items: center;
}

.sidebar .nav__upper_node_link::before, .sidebar .nav__upper_node_link:link::before, .sidebar .nav__upper_node_link:visited::before {
  content: "";
  width: 30px;
  height: 30px;
  background: #1e305e url(/images/local/icons/v-202410170829/arrow-left.png) no-repeat center center;
  border-radius: 6px;
}

.sidebar .nav.-root-nav {
  margin-top: 1.5em;
  margin-bottom: 0;
  padding: 46px;
  border-radius: 5px;
  background-color: #1e305e;
}

.sidebar .nav.nav.-sub-nav {
  margin-top: .75em;
  margin-bottom: 0;
  padding-left: 24px;
}

.sidebar .nav__list {
  padding-left: 0em;
  list-style-type: none;
  margin: 2.2em 0 1.5em;
}

.sidebar .nav__item {
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0 0 .5em;
}

.sidebar .nav__item .nav__item {
  font-size: 1.125rem;
  font-weight: 300;
  text-transform: none;
}

.sidebar .nav__item.-has-children > .nav__link {
  font-weight: 500;
}

.sidebar .nav__item.-expanded {
  background-color: rgba(0, 0, 0, 0.1);
}

.sidebar .nav__item.-current {
  background-color: rgba(0, 0, 0, 0.1);
}

.sidebar .nav__description {
  margin: 0 0 .25em;
  font-size: 1rem;
  color: #ffffff;
}

.sidebar .nav__link {
  position: relative;
}

.sidebar .nav__link + .nav.-sub-nav .nav__link.-expandable .nav__toggle-button {
  left: -26px;
  top: .36em;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg width='12' height='12' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5,5L5,1C5,0.448 5.448,0 6,0C6.552,0 7,0.448 7,1L7,5L11,5C11.552,5 12,5.448 12,6C12,6.552 11.552,7 11,7L7,7L7,11C7,11.552 6.552,12 6,12C5.448,12 5,11.552 5,11L5,7L1,7C0.448,7 0,6.552 0,6C0,5.448 0.448,5 1,5L5,5Z'/%3E%3C/svg%3E");
}

.sidebar .nav__link, .sidebar .nav__link:link, .sidebar .nav__link:visited {
  color: #ffffff;
  text-decoration: none;
}

.sidebar .nav__link:hover, .sidebar .nav__link:focus, .sidebar .nav__link:active {
  text-decoration: underline;
}

.sidebar .nav__link[aria-expanded=true] ~ .nav {
  display: block;
}

.sidebar .nav__link[aria-expanded=false] ~ .nav {
  display: none;
}

.sidebar .nav__link.-active, .sidebar .nav__link.-active-parent {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  font-weight: 800;
}

.sidebar .nav__link.-active::after, .sidebar .nav__link.-active-parent::after {
  content: "";
  width: 7px;
  height: 8px;
  background: #1e305e url(/images/local/icons/v-202410170829/arrow-left.png) no-repeat center center;
  transform: rotate(180deg);
  flex-shrink: 0;
}

.sidebar .nav__link.-active, .sidebar .nav__link.-active:link, .sidebar .nav__link.-active:visited, .sidebar .nav__link.-active-parent, .sidebar .nav__link.-active-parent:link, .sidebar .nav__link.-active-parent:visited {
  color: #ffffff;
}

.sidebar .nav__toggle-button {
  font-size: 1rem;
  line-height: 0;
  position: absolute;
  transition: transform .2s ease-out;
  transform-origin: 50% 50%;
  left: -28px;
  top: .18em;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: transparent url("data:image/svg+xml;utf8,%3Csvg width='16' height='16' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7,7L7,1C7,0.448 7.448,0 8,0C8.552,0 9,0.448 9,1L9,7L15,7C15.552,7 16,7.448 16,8C16,8.552 15.552,9 15,9L9,9L9,15C9,15.552 8.552,16 8,16C7.448,16 7,15.552 7,15L7,9L1,9C0.448,9 0,8.552 0,8C0,7.448 0.448,7 1,7L7,7Z'/%3E%3C/svg%3E") no-repeat 0 0;
}

.sidebar .nav[aria-expanded="true"] .nav__toggle-button {
  transform: rotate(225deg);
}
