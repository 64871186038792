.header {
	position: relative;
	background-color: $color-light;
	color: $color-brand;
	padding: 0 0 1.25rem;
	z-index: 30;
	@include link(){
		color: $color-brand;
	}
	@include min(600px){
		padding: 0;
	}
	@include min(1024px){
		color: $color-dark;
		@include link(){
			color: $color-dark;
		}
	}


	&__title {
		position: relative;
		margin: 0 auto;
		@include min(500px) {
			margin: 0;
		}
	}

	&__home-link {
		@include fill;
	}

	&__content {
		position: relative;
		z-index: 2;
		max-width: 1764px;
		@include flex(df, fww, jcsb, aic);
		gap: 1rem 0;
		@include min(1024px){
			flex-wrap: nowrap;
		}
	}

	&__home-link {
		color: inherit;
		text-decoration-color: none;
	}
	&__logo {
		display: block;
		font-size: 1rem;
	}

	&__heading {
		position: relative;
		text-align: center;
		padding: 0;
		color: $color-dark;
		line-height: 1;
		font-size: rem(34px);
	}
	&__subheading {
		display: block;
		margin-top: .5em;
		font-size: rem(15px);
	}
	&__heading-container {
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 3rem auto 0;
		width: 100%;
		padding: 1.25rem 4rem 0.75rem;
		@include min(800px){
			margin: 0 auto 0;
		}
		@include min(1024px){
			padding: 1rem 1.5rem 0.5rem 0;
    		margin: 0;
			width: auto;
			flex-shrink: 0;
		}
	}
	&__nav{
		display: flex;
		width: 100%;
		@include min(1024px){
			width: auto;
			flex-grow: 1;
		}
	}
	&__nav-button {
		position: absolute;
		right: 1rem;
		top: 5rem;
		display: inline-flex;
		align-items: flex-end;
		justify-content: center;
		flex-direction: column;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		@include min(600px) {
			display: none;
		}

		@include link(&) {
			text-decoration: none;
		}

		> .hamburger {
			padding: 8px 7px;
			//border: 1px solid currentColor;
			border-radius: 5px;
		}

		.nav-button__title {
			font-size: rem(15px);
			margin: 0;
		}
	}
	&__additional {
		position: absolute;
		top: 1rem;
		right: 1rem;
		@include flex(df, aife, aic);
		padding: 0;
		flex-direction: row-reverse;
		gap: 1rem;
		@include min(600px){
			gap: 0;
		}
		@include min(1024px){
			gap: 1.4rem;
			right: 0;
		}
		@include min(1360px){
			flex-direction: row;
			position: static;
		}
		@include min(1500px){
			border-left: 1px solid #8d8d8d;
			padding: 1.5rem 0 1.5rem 3rem;
		}
	}
	&__social{
		.list{
			@include flex(df, fww, aic);
			gap: 1rem;
			margin-bottom: 0;
			&__item{
				display: inline-flex;
				margin-bottom: 0;
			}
			&__link{
				display: inline-flex;
			}
			svg{
				fill: $color-dark;
			}
		}
		object {
			pointer-events: none;
		}

		@include link() {
			text-decoration: none;
			svg {
				fill: grey;
			}
		}

		@include link-over() {
			svg {
				fill: $color-dark;
			}
		}
	}
}
.virtual-anchor{
	@include flex(dif, aic, jcc);
	padding: 1rem;
	background-color: $color-light;
	gap: 1rem;
	font-size: 1.2rem;
	font-weight: 700;
	width: 100%;
	&::after{
		display: inline-block;
		content: "";
		width: 35px;
		height: 26px;
		background: transparent url(/images/local/icons/v-202410170829/virtual-anchor-arrow.png) no-repeat center right;
	}
	@include link(&){
		color: $color-brand;
		text-decoration: none;
	}
	@include link-over(&){
		text-decoration: underline;
	}
	@include min(600px){
		position: absolute;
		bottom: 7.4rem;
    	right: 3%;
		z-index: 20;
		width: auto;
	}
	@include min(1024px){
		padding: 1.4rem 4rem;
		font-size: rem(28px);
		&::after{
			width: 85px;
			height: 26px;
		}
	}
}
