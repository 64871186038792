@keyframes menuFadeIn {
	from {
		opacity: 0;
		transform: translateY(200%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes menuFadeOut {
	from {
		opacity: 1;
		transform: translateY(0);
	}
	to {
		opacity: 0;
		transform: translateY(200%);
	}
}

.main-nav {
	position: relative;
	//flex-shrink: 0;
	color: $color-brand;
	padding: 0 0 2rem;
	width: 100%;
	@include min(1024px){
		margin-left: auto;
		width: auto;
		padding: 1rem 0 0;
	}
	@include min(1360px){
		padding: 0 3rem 0 0;
	}
	.nav{
		&.-root-nav {
			@include flex(df, fww);
			margin: 0;
			padding: 0;
			box-sizing: border-box;
			gap: 0 1rem;
			flex-direction: column;
			@include min(600px){
				flex-direction: row;
			}
			@include min(1360px){
				gap: 0 3.25rem;
			}
		}
		&__item {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			flex-grow: 1;
			margin: 0 0;
			text-align: center;
			font-size: rem(27px);
			font-family: $font-headings;
		}

		&__link {
			position: relative;
			display: block;
			padding: 0.35em 0;
			justify-content: center;
			line-height: 1;
			&::after{
				content: "";
				position: absolute;
				left: -0.25rem;
				right: -0.25rem;
				bottom: 100%;
				height: 2px;
				background-color: transparent;
				transition: bottom .3s ease-in-out, background-color .6s ease-in-out;
			}

			@include link(&) {
				color: $color-brand;
				text-decoration: none;
			}
			@include link-over(&){
				color: $color-secondary;
				&::after{
					position: absolute;
					bottom: 0;
					background-color: $color-secondary;
				}
			}
			&.-active,
			&.-active-parent {
				text-decoration: none;

				@include link(&) {
					color: $color-secondary;
				}
			}
		}
		&__description {
			display: none;
		}
	}
	@include max($nav-horizontal-burger-threshold - 1px) {
		transition: max-height 0.3s ease-out;
		max-height: 0;
		overflow: hidden;
		padding: 0;
		&__item{
			animation: menuFadeOut 0.1s ease-out 0s forwards;
			opacity: 0;

			@for $li from 2 through 10 {
				$delay: (50 * ($li - 1));
				&:nth-of-type(#{$li}) {
					animation-delay: to-length($delay, "ms") !important;
				}
			}
		}

		.header__nav--toggled &{
			max-height: 20rem;

			&__item{
				animation: menuFadeIn 0.3s ease-out 0s forwards;
			}
		}
	}

}
