
.contact {
	&__line {
		margin: 0 0 1em;
		&.-phone,
		&.-email {
			padding-left: 30px;
			padding-top: .15em;
			padding-bottom: .15em;
			background: {
				repeat: no-repeat;
				position: 0 50%;
			}
		}

		&.-location {
			//background-image: encode-svg('<svg width="30" height="30" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve"><path d="M15,0.405C20.9,0.405 25.683,5.188 25.683,11.088C25.683,16.989 16.532,29.595 14.879,29.595C13.226,29.595 4.317,16.989 4.317,11.088C4.317,5.188 9.1,0.405 15,0.405ZM14.989,5.854C17.886,5.854 20.234,8.202 20.234,11.1C20.234,13.996 17.886,16.344 14.989,16.344C12.092,16.344 9.744,13.996 9.744,11.1C9.744,8.202 12.092,5.854 14.989,5.854Z" fill="#{$color-light}"/></svg>');
			margin-bottom: 3em;
		}

		&.-phone {
			background-image: url(/images/local/icons/v-202410170829/phone.png);
		}

		&.-email {
			background-image: url(/images/local/icons/v-202410170829/email.png);
		}
	}
	&__more{
		margin-top: 3.6rem;
		text-align: left;
		&-button{
			padding: 5px 2em;
			min-height: 54px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			min-width: 180px;
		}
	}
	@include link(".contact__more-button"){
		color: $color-light;
		text-decoration: none;
	}
}
