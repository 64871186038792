.mutaceGte{
	display: flex;
	align-items: center;
}
#google_translate_element {
   display: inline-block;
   position: relative;
   display: none;
   overflow: hidden;
   flex-shrink: 0;

   &.ready {
      display: inline-block;
		@include min(600px){
			margin-right: 14px;
		}
   }

   .goog-te-select {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 0;
      opacity: 0 !important;
   }

   .goog-te-gadget {
      border: 0;
      width: 100%;
      padding-bottom: 1px;
      background-color: transparent;
      font-size: 0 !important;
      position: relative;
      display: inline-flex;
      align-items: center;
      overflow: hidden;
		display:none;

      .goog-te-gadget-simple {
         display: none !important;
      }

      > * {
         font-size: 1rem !important;
      }

      &::before {
         content: '';
         width: 28px;
         height: 28px;
         background-image: url(/images/global/v-202410170829/gte_logo.svg) !important;
         background-position: 0 0 !important;
         background-size: 100% auto;
         background-repeat: no-repeat;
      }

      .goog-te-gadget-icon {
         + span {
            display: none;
         }
      }
   }
}

#jazyky {
	display:block;
   &:empty {
      display: none;
   }

   ul {
      list-style-type: none;
      margin: 0.5rem 0 0;
      padding: 0;
      display: inline-flex;
		align-items: baseline;
		gap: 0.5rem;

      li {
         margin: 0;
         font-size: 0;
			a{
				color: #c3c3c3;
				display: inline-flex;
				&::before {
					content: "";
					width: 20px;
					height: 20px;
					margin-right: .5rem;
					display: block;
					background-repeat: no-repeat;
					background-position: 50%;
				}
				&::after {
					font-size: rem(16px);
				}
			}

         .googleTranslateActiveLanguage {
            font-weight: bold;
				color: $color-dark;
         }
			.language-cs{
				&::before {
					background-image: url(/images/local/icons/gte/v-202410170829/cs.png);
				}
				&::after {
					content: 'CS';
				}
			}
			.language-en{
				&::before {
					background-image: url(/images/local/icons/gte/v-202410170829/en.png);
				}
				&::after {
					content: 'EN';
				}
			}
         .language-de{
				&::before {
					background-image: url(/images/local/icons/gte/v-202410170829/de.png);
				}
				&::after {
					content: 'DE';
				}
			}
         .language-fr::after {
            content: 'FR';
         }
         .language-pl::after {
            content: 'PL';
         }
         .language-ru::after {
            content: 'RU';
         }
         .language-hu::after {
            content: 'HU';
         }

         @include link {
            text-decoration: none;
         }

         @include link-over {
            text-decoration: underline;
         }
      }
   }
}

body[style] {
   top: 0 !important;
}

