[class*="lazy-"] {
	transition: filter .5s ease-out;
	filter: blur(20px);
}

[class*="lazy-"]:not(.loaded) {
	display: block;
	background: #dedede;
	width: 100%;
	min-width: 240px;
	padding-bottom: 56.25%;
}

[class*="lazy-"].loaded {
	filter: blur(0);
}

body {
	color: $color-base;
}

::selection {
	background-color: #1a86d0;
	color: #ffffff;
}

.main-wrapper {
	border-top: 2px solid #d2d2d2;
	@include min(800px) {
		@include flex(df, fdrr);
	}
}

.main {
	padding-top: 4.5em;

	@include min(800px) {
		flex-grow: 1;
		padding-left: 4vw;
		padding-bottom: 4em;
		.-nosidebar & {
			padding-left: 0;
		}
	}
}

.table-wrapper {
	max-width: 100%;
	overflow-x: auto;
	margin-bottom: 2em;

	table {
		margin-bottom: 1em;
	}
}

.home {
	position: relative;
	display: flex;
	flex-direction: column;
	z-index: 10;
	margin-bottom: 2.3rem;
}

.sidebar {
	position: relative;
	padding-top: 2em;
	padding-bottom: 2em;
	min-width: 340px;

	&::after {
		position: absolute;
		content: "";
		left: 100%;
		width: 100%;
		top: 0;
		bottom: 0;
		background-color: #e2e2e2;
	}

	@include min(800px) {
		width: ratio($max-width, 350px);
		flex-shrink: 0;

		&::after {
			content: normal;
		}
	}
}

.content {
	margin-left: auto;
	margin-right: auto;
}

.centered {
	max-width: $max-width;
	width: 94%;
	margin-left: auto;
	margin-right: auto;

	&.-narrow {
		max-width: 920px;
	}

	&.-padded {
		width: 94%;
		padding-left: 4%;
		padding-right: 4%;
	}

	&.-padded-left {
		padding-left: 4%;
	}

	&.-padded-right {
		padding-right: 4%;
	}
}

.more {
	text-align: center;
	&__button {
		background-color: $color-secondary;
		padding: 1.7em 2em;
		font-weight: 500;
		display: inline-block;
		font-size: rem(14px);
		font-weight: 800;
		letter-spacing: 1px;
		min-width: 235px;
		transition: background-color .3s ease-in-out, color .3s ease-in-out;
	}
}

@include link(".more__button") {
	color: $color-light;
	text-decoration: none;
}

@include link-over(".more__button") {
	background-color: $color-brand;
}


.board {
	.list {
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		padding-right: 0;
		margin: 0 0 3em;
		overflow: hidden;

		&__item {
			@include grid(650px 2);
			flex-grow: 0;
			padding: 0;
			margin: 0;
			display: flex;
			flex-direction: column;

			> :only-child {
				flex-grow: 1;
			}
		}

		.board-card {
			flex-grow: 1;
		}
	}
}


.text-component {
	margin-bottom: 2em;
	overflow: hidden;
	p:first-child {
		margin-top: 0;
	}

	p:last-child {
		margin-bottom: 0;
	}
	.figure.image{
		margin: 0;
	}
	.image-align-left,
	.image-align-right {
		margin: 0;

		img {
			@include min(500px) {
				max-width: 42.5%;
			}
		}
	}

	.image-align-left {
		img {
			float: left;
			margin: 0 1em .75em 0;
		}

		>*:first-of-type img {
			clear: both;
		}
	}

	.image-align-right {
		img {
			float: right;
			margin: 0 0 .75em 1em;
		}

		>*:first-of-type img {
			clear: both;
		}
	}

	.image-align-center {
		text-align: center;

		img {
			margin: 0 1rem .75em;
			max-width: 100%;
		}
	}

	[contenteditable="true"] {

		.images-container,
		.image {

			&:focus,
			&:hover {
				img {
					box-shadow: 0 0 5px red;
				}
			}
		}
	}
}

.additional {
	overflow: hidden;
	color: $color-brand;
	padding: 0;
	font-weight: 500;
	border: 1px solid #838b95;
	border-width: 1px 0;

	@include link {
		color: $color-light;
		text-decoration: underline;
	}

	@include link-over {
		text-decoration: none;
	}

	&__content {
		position: relative;
		max-width: 1700px;
		@include min(600px){
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 0 2rem;
			.additional__section{
				padding: 4rem 0 2rem;
				&:nth-child(even){
					padding-left: 2rem;
					padding-right: 2rem;
				}
				&.-write-us,
				&.-links{
					&::before{
						left: 100%;
					}
				}
			}
		}
		@include min(1360px){
			grid-template-columns: 0.8fr 1.4fr 1.1fr 1.1fr;
			gap: 4rem;
			.additional__section{
				padding: 6.2rem 0 2rem;
				&.-write-us{
					&::before{
						content: normal;
					}
				}
				&:nth-child(even){
					padding-left: 4rem;
					padding-right: 4rem;
				}
			}
		}
	}

	&__section{
		padding: 2rem 0 2rem;
		@include link(){
			color: $color-brand;
		}
		&:nth-child(even) {
			background-color: $color-brand;
			color: $color-light;
			.element-type-heading {
				color: $color-light;
			}
			@include link(){
				color: $color-light;
			}
		}
		&.-write-us{
			position: relative;
			@include pad($color-brand, right);
			&::before{
				z-index: -1;
				left: -100%;
			}
		}
		&.-links{
			position: relative;
			@include pad($color-brand, right);
			min-height: 450px;
			&::before{
				z-index: -1;
				left: -100%;
			}
			//&::after{
			//	position: absolute;
			//	content: "";
			//	left: 75%;
			//	width: 156px;
			//	height: 211px;
			//	background: transparent url(/images/local/v-202410170829/logo-white.png) no-repeat center center;
			//}
		}
	}
	.element-type-heading {
		font-size: rem(36px);
		color: $color-secondary;
		font-weight: 400;
		margin-bottom: 1.25em;
		@include min(600px){
			font-size: rem(41px);
		}
	}
	&__link-to-header{
		position: absolute;
		right: 0;
		bottom: 0;
		width: 102px;
		height: 102px;
		border-radius: 50%;
		@include flex(df, aic, jcc);
		background-color: $color-secondary;
		transform: translateY(50%);
	}
}
.-additional__link-to-header{
	position: relative;
	max-width: 1780px;
}

.all_rights {
	&__logo {
		display: block;
		margin-bottom: 0.5em;
	}
	&__subheading {
		margin-top: 0.5em;
		font-size: 1rem;
		display: block;
		font-weight: 300;
		font-family: $font-body;
	}
}

.attachments {
	margin: 0 0 3em;
	grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
	grid-gap: 3rem 1.5rem;
}

.section.-banners{
	padding-top: 4rem;
	padding-bottom: 0;
	.centered{
		max-width: 1540px;
	}
}

.banners{
	&__bottom-bg{
		display: block;
		margin: 0 auto;
	}
}
