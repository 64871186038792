
.contact-form {
	padding-top: 0;

	.form__section {
		margin-bottom: .5em;
	}

	&__input,
	&__textarea {
		background-color: $color-light;
		border-color: $color-dark;
		color: $color-dark;
		border-radius: 1px;
		font-style: italic;
	}

	&__input {
		min-height: 55px;
	}

	&__info:first-child {
		margin: .55em 0 .9em;
	}

	&__button.-primary{
		padding: 5px 2em;
		font-size: 1rem;
		&:not([disabled]){
			@include link-over(&){
				background-color: $color-light;
				color: $color-brand;
			}
		}
	}
}
